import { useEffect } from 'react';
import { useAuth } from 'contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useLogging } from 'contexts/logging';

export default function LoginRedirect() {
    const authContext = useAuth();
    const logging = useLogging();
    const navigate = useNavigate();

    useEffect(() => {
        async function checkAuthentication() {
            try {
                const session = await authContext.getSession();
                if (session) {
                    // User is logged in, redirect to the "/investor" path
                    navigate('/investor');
                } else {
                    // User is not logged in, redirect to the "/" path
                    navigate('/');
                }
            } catch (error) {
                logging.log('LoginRedirect :: checkAuthentication :: error :: ', error);
            }
        }
        checkAuthentication();
    }, [logging, authContext, navigate]);

    return <>Redirecting...</>;
}
