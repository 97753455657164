import React from 'react';
import Image from 'mui-image';
import { Typography, Grid, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import staticAssetsURL from 'utils/staticAssetsURL';

/**
 * @name ConfirmationStep
 *
 * @desc ConfirmationStep Component.
 * Renders a single page for the submission confirmation for the Entry Form
 *
 * @component
 * @category Entry Form
 * @return {React.ComponentType} Confirmation Step component JSX.
 * @example
 * (Form Model)
 * {... steps: [ ..., { order: 1, name: 'Confirmation', render: <ConfirmationStep />, formField: {} }, ...]}
 */
export default function ConfirmationStep() {
    const submissionGraphic = `${staticAssetsURL()}images/Submission-Page-Graphic.png`;

    // TODO: Add in Email confirmation notice
    return (
        <Grid container spacing={3} sx={{ mt: 10 }} alignItems="center" align="center">
            <Grid item xs={6}>
                <Grid container spacing={2} sx={{ px: 17, py: 8 }}>
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            Thank you for your submission!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Thank you for choosing Visio to help you with your journey! An account executive will be in contact
                            with you shortly.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            to="https://visiolending.com"
                            size="larger"
                            variant="contained"
                            component={RouterLink}
                            sx={{ px: 1 }}
                        >
                            Go to Homepage
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Image src={submissionGraphic} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
