import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import { useAuth } from 'contexts/AuthProvider';
import { useLogging } from 'contexts/logging';
import FormWizard from 'features/FormWizard/FormWizard';
import formModel from './model';
import { SubmitComponent } from './FormFields';

/**
 * @name EntryForm
 *
 * @desc EntryForm Component.
 * Renders a single page with a formik component, pages defined via form model
 *
 * @component
 * @category Entry Form
 * @return {React.ComponentType} Entry Form Demo Page component JSX.
 * @example
 * See routes.jsx:
 * path: '/', children: [ { path: 'entry', element: <EntryForm /> } ]
 */
export default function EntryForm({ initialData }) {
    return (
        <>
            <Helmet>
                <title> Entry Form | Visio Lending </title>
            </Helmet>
            <Grid container sx={{ pl: 6, py: 2 }}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <FormWizard formModel={formModel} SubmitComponent={SubmitComponent} initialData={initialData} />
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </>
    );
}

EntryForm.propTypes = {
    initialData: PropTypes.instanceOf(Object),
};

export function withUserCheck(WrappedComponent) {
    return function WithUserCheck() {
        const authContext = useAuth();
        const logging = useLogging();
        const [initialData, setInitialData] = useState({});

        useEffect(() => {
            // if user info exists, pass info as initialData
            async function checkSession() {
                const user = await authContext.getUser();
                logging.log('WithUserCheck :: user :: ', user);

                if (user) {
                    await setInitialData({
                        firstName: user?.given_name || '',
                        lastName: user?.family_name || '',
                        email: user?.email || '',
                        phone: user?.['custom:sf_phone_number'].replace(/\D/g, '') || '',
                    });
                }
            }
            checkSession();
        }, [authContext, logging]);

        return <WrappedComponent initialData={initialData} />;
    };
}
