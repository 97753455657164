import { Suspense } from 'react';

import Loader from './Loader';

/**
 * @name Loadable
 * @desc Loadable Component
 * Higher-order component that lazily loads the provided Component asynchronously and displays a loader during the loading process.
 *
 * @component
 * @category Common
 * @param {React.ComponentType} Component - The component to be lazily loaded.
 * @returns {React.ComponentType} A component that lazily loads and renders the provided Component.
 *
 * @example <caption>Usage in routes</caption>
 * const MinimalLayout = Loadable(lazy(() => import('./layouts/minimal')));
 *
 * @see https://javascript.info/currying-partials
 */
export default function Loadable(Component) {
    return function inner(props) {
        return (
            <Suspense fallback={<Loader />}>
                <Component {...props} />
            </Suspense>
        );
    };
}
