import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Button, CircularProgress } from '@mui/material';

/**
 * @name SubmitComponent
 *
 * @desc Submit Component.
 * Renders a Submit button for a form
 *
 * @component
 * @category Form Fields
 * @param {Object} props - Form properties for submitting, {isValid=Boolean, isSubmitting=Boolean}
 * @return {React.ComponentType} SubmitComponent component JSX.
 * @example
 * <FormWizard formModel={formModel} SubmitComponent={SubmitComponent} />
 */
function SubmitComponent(props) {
    const { isValid, isSubmitting } = props;

    return (
        <Grid container spacing={0}>
            <Grid item xs={4} />
            <Grid item xs={6.4}>
                <Box display="flex" justifyContent="flex-end">
                    {isSubmitting ? (
                        <CircularProgress size={24} color="primary" />
                    ) : (
                        <Button disabled={!isValid || isSubmitting} type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                    )}
                </Box>
            </Grid>

            <Grid item xs={1.6} />
        </Grid>
    );
}

SubmitComponent.propTypes = {
    isValid: PropTypes.bool,
    isSubmitting: PropTypes.bool,
};

export default SubmitComponent;
