import * as Yup from 'yup';

import { postEntryForm } from 'services/shepherd/entryFormService';

import EntryFormStep from './Step/EntryFormStep';
import ConfirmationStep from './Step/ConfirmationStep';

const submissionPreprocess = (values) => {
    return {
        loan_type: values.loanType,
        investment_strategy: values.strategy,
        units: values.units,
        property_zipcode: values.location?.zipcode,
        property_location: values.location?.address,
        rent_monthly_estimated: values.monthlyRent,
        property_value_estimated: values.propertyValue,
        property_rented: 'N',
        credit_score: values.creditScore,
        name_first: values.firstName,
        name_last: values.lastName,
        email: values.email,
        phone: values.phone.replace(/\D/g, ''),
        property_street: values.location?.street_line || '',
        property_city: values.location?.city,
        property_state: values.location?.state,
        source: values.source,
    };
};

const formModel = {
    formId: 'prequal',
    submissionAPI: postEntryForm,
    submissionPreprocess,
    submissionStep: 'EntryFormStep',
    showBackButton: false,
    steps: [
        {
            order: 0,
            name: 'EntryFormStep',
            render: <EntryFormStep />,
            formField: {
                location: {
                    name: 'location',
                    label: 'Property Address',
                    initial: '',
                },
                strategy: {
                    name: 'strategy',
                    label: 'Rental Type',
                    initial: 'LTR',
                    options: [
                        {
                            value: 'LTR',
                            label: 'Long Term',
                        },
                        { value: 'STR', label: 'Short Term' },
                    ],
                },
                propertyValue: {
                    name: 'propertyValue',
                    label: 'Property Value',
                    initial: '',
                },
                monthlyRent: {
                    name: 'monthlyRent',
                    label: 'Estimated or Current Monthly Rent',
                    initial: '',
                },
                loanType: {
                    name: 'loanType',
                    label: 'Loan Type',
                    initial: 'Cash-Out Refinance',
                    options: [
                        {
                            value: 'Cash-Out Refinance',
                            label: 'Cash Out Refinance',
                        },
                        {
                            value: 'Rate & Term Refinance',
                            label: 'Rate and Term Refinance',
                        },
                        { value: 'Purchase', label: 'Purchase' },
                    ],
                },
                units: {
                    name: 'units',
                    label: 'Number of Units',
                    initial: '1',
                    options: [
                        { value: '1', label: '1 Units' },
                        { value: '2', label: '2 Units' },
                        { value: '3', label: '3 Units' },
                        { value: '4', label: '4 Units' },
                        { value: '5', label: '5+ Units' },
                    ],
                },
                firstName: {
                    name: 'firstName',
                    label: 'First Name',
                    initial: '',
                },
                lastName: {
                    name: 'lastName',
                    label: 'Last Name',
                    initial: '',
                },
                email: {
                    name: 'email',
                    label: 'Email',
                    initial: '',
                },
                phone: {
                    name: 'phone',
                    label: 'Phone Number',
                    initial: '',
                },
                creditScore: {
                    name: 'creditScore',
                    label: 'Estimated Credit Score',
                    initial: '',
                    tooltip:
                        "If you aren't sure what your credit score is, that's okay: try your best to give as close of an estimate as you can.",
                },
                source: {
                    name: 'source',
                    label: '',
                    initial: 'Direct Traffic',
                },
            },
            validationSchema: Yup.object().shape({
                location: Yup.mixed().required('Location is required'),
                strategy: Yup.string().required('Rental Type selection is required'),
                monthlyRent: Yup.number().typeError('Rent must be a number').required('Rent is required'),
                propertyValue: Yup.number()
                    .typeError('Value must be a number')
                    .required('Property Value is required')
                    .min(100000, 'Minumum purchase price is $100,000'),
                loanType: Yup.string().required('Loan Type selection is required'),
                units: Yup.string().required('Unit number selection is required'),
                creditScore: Yup.number()
                    .typeError('Value must be a number')
                    .required('Credit Score is required')
                    .max(850, 'Max Credit Score is 850')
                    .min(600, 'Min Credit Score is 600'),
                firstName: Yup.string().required('First Name is required'),
                lastName: Yup.string().required('Last Name is required'),
                email: Yup.string().required('Email is required').email('Not a valid email address'),
                phone: Yup.string()
                    .required('Phone is required')
                    .matches(
                        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                        'Invalid Phone Number format',
                    ),
            }),
        },
        {
            order: 1,
            name: 'Confirmation',
            render: <ConfirmationStep />,
            formField: {},
        },
    ],
};

export default formModel;
