import React from 'react';
import ReactDOM from 'react-dom/client';
// import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import packageJson from '../package.json';

// Sentry.init({
//     dsn: 'https://9d9fe31d2d5649f79d1127233d3348f2@o547664.ingest.sentry.io/6505510',
//     integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//     release: packageJson.version,
//     tracesSampleRate: 1.0,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     environment: process.env.REACT_APP_ENVIRONMENT,
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
