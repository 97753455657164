import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { AuthProvider } from 'contexts/AuthProvider';
import { LoggingProvider } from 'contexts/logging';

import Router from './routes';
import ThemeProvider from './themes';

function App() {
    return (
        <LoggingProvider>
            <AuthProvider>
                <HelmetProvider>
                    <BrowserRouter>
                        <ThemeProvider>
                            <Router />
                        </ThemeProvider>
                    </BrowserRouter>
                </HelmetProvider>
            </AuthProvider>
        </LoggingProvider>
    );
}

export default Sentry.withProfiler(App);
