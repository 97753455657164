import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { AddressLookupField, RadioField, SelectField, InputField, InfoToolTip } from '../FormFields';

// function FormLabel({ label }) {
//     return (
//         <Typography variant="h6" gutterBottom sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular }}>
//             {label}
//         </Typography>
//     );
// }

// FormLabel.propTypes = {
//     label: PropTypes.string,
// };

/**
 * @name EntryFormStep
 *
 * @desc EntryFormStep Component.
 * Renders a single page for the Form input step for the Entry Form.
 *
 * @component
 * @category Entry Form
 * @param {Object} formField - The JSON model of the form data to be rendered by form field components.
 * @return {React.ComponentType} Entry Form (form input) Step component JSX.
 * @example
 * (Form Model)
 * {... steps: [ ..., { order: 0, name: 'EntryFormStep', render: <EntryFormStep />, formField: {...fields} }, ...]}
 */
export default function EntryFormStep({ formField }) {
    const { location, strategy, propertyValue, monthlyRent, loanType, units, firstName, lastName, email, phone, creditScore } =
        formField;
    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ borderBottom: 2, borderColor: (theme) => theme.palette.grey[300] }}>
                        <Typography
                            variant="h4"
                            sx={{
                                color: (theme) => theme.palette.primary.main,
                                fontWeight: (theme) => theme.typography.fontWeightLight,
                            }}
                        >
                            Visio Lending Entry Form
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular }}>
                            Please fill out the information below and we will get back to you as soon as we can.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular }}
                        >
                            Property Information
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <AddressLookupField name={location.name} label={location.label} fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <SelectField name={units.name} label={units.label} options={units.options} fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <InputField name={propertyValue.name} label={propertyValue.label} fullWidth />
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant="caption">{strategy.label}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioField name={strategy.name} label={strategy.label} options={strategy.options} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <InputField name={monthlyRent.name} label={monthlyRent.label} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant="caption">{loanType.label}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioField name={loanType.name} label={loanType.label} options={loanType.options} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular }}
                        >
                            Borrower Information
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <InputField name={firstName.name} label={firstName.label} fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <InputField name={lastName.name} label={lastName.label} fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <InputField name={email.name} label={email.label} fullWidth />
                    </Grid>
                    <Grid item xs={8}>
                        <InputField name={phone.name} label={phone.label} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <InputField name={creditScore.name} label={creditScore.label} fullWidth />
                            </Grid>
                            <Grid item xs={1}>
                                <InfoToolTip tooltip={creditScore.tooltip} placement="right" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

EntryFormStep.propTypes = {
    formField: PropTypes.instanceOf(Object),
};
