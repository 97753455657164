import React from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';

/**
 * @name InfoToolTip
 *
 * @desc InfoToolTip Component.
 * Renders a tool tip icon component
 *
 * @component
 * @category Form Fields
 * @param {Object} props - JSON object of the form field properties: tooltip = the message to be displayed, placement = the direction to show the message
 * @return {React.ComponentType} InfoToolTip component JSX.
 * @example
 * <InfoToolTip tooltip={field.tooltip} placement={field.placement} />
 */
function InfoToolTip(props) {
    const { tooltip, placement, ...rest } = props;

    return (
        <Tooltip title={tooltip} disableFocusListener disableInteractive placement={placement} {...rest}>
            <IconButton sx={{ p: 0, mt: 2 }}>
                <InfoOutlined
                    sx={{
                        fontSize: (theme) => theme.typography.h3.fontSize,
                        color: (theme) => theme.palette.grey[800],
                    }}
                />
            </IconButton>
        </Tooltip>
    );
}

InfoToolTip.propTypes = {
    tooltip: PropTypes.string,
    placement: PropTypes.string,
};

export default InfoToolTip;
